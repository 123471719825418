<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="套餐" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="flex justify-start">
			<el-form :inline="true">
				<el-form-item>
					<div class="flex align-center">
						<el-avatar :src="member.avatar" icon="el-icon-user-solid"></el-avatar>
						<div class="margin-left">{{member.real_name}}</div>
					</div>
				</el-form-item>
				<!-- <el-form-item label="持有卡片">
					{{member.member_card_count}}
				</el-form-item>
				<el-form-item label="失效卡片">
					{{member.invalid_card}}
				</el-form-item> -->
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="set_meal_name" label="套餐名称" width="300">
			</el-table-column>
			<el-table-column prop="card_item" label="包含项目" width="300">
				<template slot-scope="scope">
					<div v-for="item in scope.row.set_meal_item" :key="item.id">{{item.name}}({{item.number-item.is_use}}/{{item.number}})</div>
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label="购买时间"></el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false,
				member: {
					id: this.$route.params.memberId,
					real_name: '',
					avatar: ''
				},
				tableData: []
			};
		},
		mounted() {
			// 查询列表
			this.$api.member.getMemberGroups({
				member_id: this.member.id,
				page: 1,
				page_size: 999
			}).then(res => {
				this.tableData = res.data.data
			})
			// 查询会员
			this.$api.member.getMember({
				id: this.member.id
			}).then(res => {
				this.member = res.data
				console.log(res)
			})

		},
		methods: {

		},
	};
</script>

<style scoped lang="scss">

</style>
